<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <a-spin :spinning="isLoading">
      <a-alert v-if="record.audit_status==0" type="warning"
               message="当前版本仅供演示，如需使用请填写以下信息并提交审核，审核通过成为正式版即可使用" banner/>
      <a-alert v-if="record.audit_status==1" type="info" message="企业信息审核中，请耐心等待" banner/>
      <a-alert v-if="record.audit_status==3" type="error" message="您的申请未通过，请检查内容，并重新提交审核" banner/>
      <a-form :form="form" @submit="handleSubmit">
        <!--        <a-form-item label="平台名称" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <a-input-->
        <!--              v-decorator="['store_name', {rules: [{required: true, message: '请输入平台名称'}]}]"-->
        <!--          />-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="当前版本" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <b style="font-size: 18px;color: #04be02" v-if="record.status==1">正式版</b>-->
        <!--          <b style="font-size: 18px" v-else>演示版</b>-->
        <!--        </a-form-item>-->

        <a-form-item label="公司名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入公司名称"
              v-decorator="['corporate_name', {rules: [{required: true, message: '请输入公司名称'}]}]"
          />
        </a-form-item>

        <a-form-item label="公司简介" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
              placeholder="简洁介绍公司信息"
              v-decorator="['describe', {rules: [{message: '公司简介不能为空'}]}]"
              :auto-size="{ minRows: 5, maxRows: 10 }"
          />
        </a-form-item>
        <a-form-item label="营业执照" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <UploadImage
              :file="record.yyzz"
              v-decorator="['yyzz_id', { rules: [{ required: true, message: '营业执照不能为空' }] }]"
          />
        </a-form-item>
        <a-form-item label="道路运输许可证" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <UploadImage
              :file="record.dlysxkz"
              v-decorator="['dlysxkz_id', { rules: [{ required: true, message: '道路运输许可证不能为空'  }] }]"
          />
        </a-form-item>
        <a-form-item label="企业授权委托书" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <UploadImage
              :file="record.qysqwts"
              v-decorator="['qysqwts_id', { rules: [{ required: true, message: '企业授权委托书不能为空'  }] }]"
          />
          请下载<a
            href="https://zcjh.iyexi.com/downloads/%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6.doc">企业授权委托书模板</a>，填写相关信息，盖章后拍照上传；
        </a-form-item>
        <a-form-item label="企业公章" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <UploadImage
              :file="record.seal_file"
              v-decorator="['seal_file_id']"
          />
        </a-form-item>
        <a-form-item label="其他资料" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <UploadImage
              :file="record.qtzl"
              v-decorator="['qtzl_id']"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: wrapperCol.span, offset: labelCol.span }">
          <a-button v-if="record.audit_status==2" type="primary" html-type="submit">保存</a-button>
          <a-button v-if="record.audit_status==1" type="primary" :disabled="true">审核中</a-button>
          <a-button v-if="record.audit_status==0" type="primary" html-type="submit">提交审核</a-button>
          <a-button v-if="record.audit_status==3" type="primary" html-type="submit">重新提交审核</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import * as Api from '@/api/store'
import {SelectImage, UploadFile, UploadImage} from '@/components'

export default {
  components: {
    UploadImage,
    SelectImage,
    UploadFile
  },
  data() {
    return {
      // 标签布局属性
      labelCol: {span: 4},
      // 输入框布局属性
      wrapperCol: {span: 10},
      // loading状态
      isLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录详情
      record: {}
    }
  },
  // 初始化数据
  created() {
    // 获取当前详情记录
    this.getDetail()
  },
  methods: {

    // 获取当前详情记录
    getDetail() {
      this.isLoading = true
      Api.info()
          .then(result => {
            // 当前记录
            this.record = result.data.storeInfo
            // 设置默认值
            this.setFieldsValue()
          })
          .finally(result => {
            this.isLoading = false
          })
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {record, form: {setFieldsValue}} = this
      // 表单内容
      this.$nextTick(() => {
        setFieldsValue(pick(record, ['corporate_name', 'describe', 'yyzz_id', 'dlysxkz_id', 'qysqwts_id', 'seal_file_id', 'qtzl_id']))
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      // 表单验证
      const {form: {validateFields}} = this
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.isLoading = true
      Api.update({form: values})
          .then((result) => {
            // 显示提示信息
            this.$message.success(result.message, 1.5)
            this.getDetail()

          })
          .finally((result) => {
            this.isLoading = false
          })
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-control {
  padding-left: 10px;

  .ant-form-item-control {
    padding-left: 0;
  }
}
</style>
